// DAO
import distributionDAO from '@/database/assetPoolDistributions';
import dispatchDAO from '@/database/dispatches';

// Others
import _ from 'lodash';


function getDefaultDistributionObj() {
    return {
        company: '',
        companyId: '',
        connectedCompany: '',
        connectedCompanyId: '',
        connectedStorageLocation: '',
        connectedStorageLocationId: '',
        type: '',
        assetType: '',
        assetTypeId: '',
        assetLimit: 0,
        total: 0,
        addTotal: 0,
        notes: '',
        dateCreated: null,
        createdBy: '',
        dateUpdated: null,
        updatedBy: '',
    };
}

function getDistributionTypeOptions() {
    return [{
        value: null,
        text: ' - Please select - '
    },
    {
        value: 'On-Hand',
        text: 'On-Hand'
    },
    {
        value: 'On-Hire',
        text: 'On-Hire'
    },
    {
        value: 'Dehire',
        text: 'Dehire'
    },
    {
        value: 'Transferred',
        text: 'Transferred'
    }
    ]
}

function getDistributionType(distribution, companyObj, connectedCompanyObj, assetTypeOriginId) {
    if (distribution.companyId === distribution.connectedCompanyId) {
        return 'On-Hand';
    } else {
        if (companyObj.type === 'Asset Service Provider' && assetTypeOriginId === companyObj.id) {
            return 'On-Hire';
        } else if (connectedCompanyObj.type === 'Asset Service Procider' && assetTypeOriginId === connectedCompanyObj.id) {
            return 'Dehire';
        } else {
            return 'Transferred';
        }
    }
}

function cleanupFields(distribution) {
    let cleanedObj = { ...distribution };

    delete cleanedObj['Date Created'];
    delete cleanedObj['Date Updated'];
    delete cleanedObj['connection'];
    delete cleanedObj['totalInTransit'];
    delete cleanedObj['totalEstimate'];
    delete cleanedObj['_showDetails'];

    return cleanedObj;
}

async function retrieveTotalAvailableAssets(locObj, assets) {
    let companyId = locObj.companyId ? locObj.companyId : '';
    let storageLocationId = locObj.id ? locObj.id : '';

    let distributions = await distributionDAO.getDistributions(companyId, companyId, storageLocationId);
    let inTransitDispatches = await dispatchDAO.getInTransitDispatchesFromSource(companyId, storageLocationId);

    _.forEach(assets, asset => {
        let assetTypeId = asset.assetTypeId;

        const distribution = _.find(distributions, o => {
            return o.connectedCompanyId === companyId
                && o.connectedStorageLocationId === storageLocationId
                && o.assetTypeId === assetTypeId;
        });
        let totalInTransit = getTotalInTransitAssets(inTransitDispatches, assetTypeId);

        let totalRemaining = distribution ? distribution.total : 0;
        totalRemaining -= totalInTransit;

        asset.totalRemaining = totalRemaining;
    });
}

function getTotalInTransitAssets(dispatches, assetTypeId) {
    let totalInTransit = 0;

    if (dispatches && !_.isEmpty(dispatches)) {
        _.forEach(dispatches, dispatch => {
            let dispatchAsset = _.find(dispatch.assets, o => {
                return o.assetTypeId === assetTypeId;
            });

            if (dispatchAsset && dispatchAsset.expectedQuantity) {
                totalInTransit += dispatchAsset.expectedQuantity;
            }
        });
    }

    return totalInTransit;
}

export const AssetPoolDistributionUtil = {
    getDefaultDistributionObj,
    getDistributionTypeOptions,
    getDistributionType,
    cleanupFields,
    retrieveTotalAvailableAssets,
    getTotalInTransitAssets
}