/* eslint-disable no-useless-escape */
// Util
import { DateUtil } from '@/utils/dateutil';

// Others
import config from '@/config/env-constants';
import _ from 'lodash';

export const ValidationUtil = {

	isValidURL(str) {
		let regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-/]))?/;
		return !(!regex.test(str));
	},

	// Only allows a-z, A-z, 0-9 and hyphen
	isAlphaNumeric(string) {
		let result = (/^([Ñña-zA-Z\-0-9])*$/.test(string));
		return result;
	},

	isAlphaNumericWithWhiteSpace(string) {
		let result = (/^([Ñña-zA-Z\-0-9\s])*$/.test(string));
		return result;
	},

	isAlphaNumericWithWhiteSpaceAndComma(string) {
		let result = (/^([Ñña-zA-Z\-0-9\s,])*$/.test(string));
		return result;
	},

	isAlphaWithWhiteSpace(string) {
		let result = (/^([Ñña-zA-Z\-.\s])*$/.test(string));
		return result;
	},

	isAlphaWithWhiteSpaceAndComma(string) {
		let result = (/^([a-zA-Z\-,\s])*$/.test(string));
		return result;
	},

	isCharacter(string) {
		let result = (/^([a-zA-Z])*$/.test(string));
		return result;
	},

	isNumeric(string) {
		let result = (/^([0-9])*$/.test(string));
		return result;
	},

	isValidPlateNo(plateNo) {
		return config.plateNoRegex.test(plateNo);
	},

	isValidMobileNumber(mobile) {
		return /^(?:\+?\d{1,3}[ -]?)?\(?\d{3}\)?[ -]?\d{3}[ -]?\d{4}$/.test(mobile);
	},

	isValidTelephoneNumber(telephone) {
		return /^\+?\d{0,3}[-.\s]?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/.test(telephone);
	},

	isFutureDate(date) {
		return DateUtil.getTimestamp(date) > DateUtil.getCurrentTimestamp();
	},

	isValidActualAssets(assets) {
		let isValid = true;
		if (!_.isEmpty(assets)) {
			_.forEach(assets, asset => {
				if (asset.actualQuantity <= 0)
					isValid = false;
			});
		}
		return isValid;
	},

	objectHasField(fieldName, fieldValue, objToCompare) {
		if (objToCompare && !_.isEmpty(objToCompare)) {
			let lowerCaseNames = []

			_.forEach(objToCompare, objectItem => {
				if (objectItem[fieldName] && typeof objectItem[fieldName] === 'string') {
					lowerCaseNames.push(objectItem[fieldName].toLowerCase());
				}
			});

			let lowerCaseFieldValue = fieldValue && typeof fieldValue === 'string' ? fieldValue.toLowerCase() : '';
			return lowerCaseNames.includes(lowerCaseFieldValue);
		}
		return false;
	},

	arrayHasValue(optionArr, fieldName, valueToCompare) {
		let found = false;
		if (optionArr && optionArr.length > 0) {
			found = optionArr.some(function (el) {
				if (el.value === null) {
					return false;
				}
				return el.value[fieldName] === valueToCompare;
			});
		}
		return found;
	},

	arrayHasValueDirectField(optionArr, fieldName, valueToCompare) {
		let found = false;
		if (optionArr && optionArr.length > 0) {
			found = optionArr.some(function (el) {
				return el[fieldName] === valueToCompare;
			});
		}
		return found;
	},

	// return empty string for null param
	removeExcessWhiteSpace(stringValue) {
		let trimmedStr = '';
		if (stringValue) {
			trimmedStr = stringValue.trim().replace(/\s{2,}/g, ' ');
		}
		return trimmedStr;
	},

	arrayContains(array, letters) {
		let status = false;
		letters.forEach(letter => {
			array.forEach(item => {
				if (item.includes(letter)) status = letter;
			});
		});
		return status;
	}
}
