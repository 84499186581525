import { db } from '@/config/firebase';

async function getDistributions(companyId, conCompanyId, conLocId, assetTypeId) {
    let assetPoolDistributions = {};

    let query = db.collection('assetPoolDistributions');
    if (companyId) {
        query = query.where('companyId', '==', companyId);
    }
    if (conCompanyId) {
        query = query.where('connectedCompanyId', '==', conCompanyId);
    }
    if (conLocId) {
        query = query.where('connectedStorageLocationId', '==', conLocId);
    }
    if (assetTypeId) {
        query = query.where('assetTypeId', '==', assetTypeId);
    }

    const querySnapshot = await query.get();
    querySnapshot.forEach(item => {
        let assetPoolDistribution = item.data();
        assetPoolDistribution.id = item.id;
        assetPoolDistributions[item.id] = assetPoolDistribution;
    });

    return assetPoolDistributions;
}


export default {
    getDistributions
}